<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div v-show="nohigh" style="float: left">
            <el-form-item>
              <el-input
                v-model="searchForm.pvipName"
                size="medium"
                placeholder="会员姓名"
                clearable
                @keyup.enter.native="getListSyPrivateVip"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.pvipPhone"
                size="medium"
                placeholder="手机号"
                clearable
                @keyup.enter.native="getListSyPrivateVip"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="search" size="medium">搜索</el-button>
              <el-button @click="exportBtn" icon="el-icon-download" size="medium"
                >导出</el-button
              >
              <el-button @click="refresh" icon="el-icon-refresh-right" size="medium"
                >刷新</el-button
              >
              <!-- <el-button :disabled="labelDisable" @click="syncLabel" icon="el-icon-refresh" size="medium">{{this.labelTitle}}</el-button> -->
            </el-form-item>
            <!-- <el-form-item>
              <el-button @click="getDictList" size="medium">重置</el-button>
            </el-form-item> -->
          </div>

          <div style="float: right; margin-right: 20px">
            <el-form-item class="high_serch">
              <el-button
                @click="showHighSerch"
                :icon="nohigh ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                type="text"
                size="medium"
                >高级搜索</el-button
              >
            </el-form-item>
          </div>
          <div v-show="!nohigh" style="float: left; width: 90%">
            <el-form-item label="手机号:">
              <el-input
                v-model="searchForm.pvipPhone"
                size="medium"
                placeholder="手机号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="会员姓名:">
              <el-input
                v-model="searchForm.pvipName"
                size="medium"
                placeholder="会员姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="会员昵称:">
              <el-input
                v-model="searchForm.pvipNickname"
                size="medium"
                placeholder="会员昵称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="注册时间:">
              <el-date-picker
                v-model="openTime"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属门店:">
              <el-select v-model="searchForm.pvipSoeId" filterable placeholder="请选择">
                <el-option label="请选择" :value="-1"
                  >&#45;&#45;请选择&#45;&#45;</el-option
                >
                <el-option v-if="meName !== ''" :label="meName" :value="0">{{
                  this.meName
                }}</el-option>
                <el-option
                  v-for="item in soeIdOptions"
                  :key="item.soe_id"
                  :label="item.soe_name"
                  :value="item.soe_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否删除卡:">
              <el-select v-model="pvipIsdel" placeholder="是否删除卡">
                <el-option value="" label="全部">全部</el-option>
                <el-option
                  v-for="item in pvipIsdelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否关注公众号:">
              <el-select v-model="pvipPublic" placeholder="是否关注公众号">
                <el-option value="" label="全部">全部</el-option>
                <el-option
                  v-for="item in pvipPublicOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会员等级:">
              <el-select v-model="Level" placeholder="会员等级">
                <el-option value="" label="全部">全部</el-option>
                <el-option
                  v-for="item in LevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="会员卡号:">
              <el-input v-model="searchForm.pvipCards" size="medium" placeholder="会员卡号" clearable></el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium"
                >搜索</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                >重置</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button @click="exportBtn" icon="el-icon-download" size="medium"
                >导出</el-button
              >
            </el-form-item>
          </div>
          <div style="float: right">
            <el-form-item>
              <el-button
                type="text"
                icon="el-icon-refresh-left"
                @click="infoSynchro"
                size="medium"
                >同步信息</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                v-if="hasAuth('menber:direct')"
                type="text"
                icon="el-icon-chat-dot-square"
                @click="dialogPatch"
                size="medium"
                >直接群发</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                v-if="hasAuth('menber:choice')"
                type="text"
                icon="el-icon-chat-square"
                @click="dialogToPoint"
                size="medium"
                :disabled="choiceDisabled"
                >选择群发</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTables"
      :data="tableData"
      v-loading="tableLoading"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '1px 0' }"
      @selection-change="handleSelectionChange"
      @select="selectItem"
      @select-all="selectAll"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column prop="pvipId" label="序号" align="center" width="90">
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.pvipId }}</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="pvipId" label="会员id" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column
        prop="pvipPhone"
        label="手机号"
        width="130"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.pvipPhone }}</a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="pvipName"
        label="会员姓名"
        show-overflow-tooltip
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black; " href="#">{{
              scope.row.pvipName
            }}</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="pvipHeads"
        label="头像"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <img :src="scope.row.pvipHeads" class="circle" />
        </template>
      </el-table-column> -->

      <el-table-column
        prop="pvipNickname"
        label="会员昵称"
        show-overflow-tooltip
        align="center"
        width="150px"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.pvipNickname }}</a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="openTime"
        width="150"
        label="注册时间"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.openTime }}</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="pVipOpDm"
        label="开卡时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column> -->
      <!-- <el-table-column
        label="是否开卡"
        show-overflow-tooltip
        align="center"
      >
      <el-tag type="success" size="small"
            >是</el-tag
          >
    </el-table-column> -->
      <el-table-column
        prop="rendEmployees"
        label="推荐员工"
        show-overflow-tooltip
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.rendEmployees }}</a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="pvipPublic"
        label="是否关注公众号"
        show-overflow-tooltip
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)" style="cursor: pointer">
            <el-tag type="warning" size="small" v-if="scope.row.pvipPublic === 1"
              >已关注</el-tag
            >
            <el-tag type="danger" size="small" v-else-if="scope.row.pvipPublic === 0"
              >未关注</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="pvipIsdel"
        label="是否删除卡"
        show-overflow-tooltip
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)" style="cursor: pointer">
            <el-tag type="danger" size="small" v-if="scope.row.pvipIsdel === 0"
              >是</el-tag
            >
            <el-tag type="success" size="small" v-else-if="scope.row.pvipIsdel === 1"
              >否</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="pvipLevel"
        label="会员等级"
        show-overflow-tooltip
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)" style="cursor: pointer">
            <el-tag type="success" size="small" v-if="scope.row.pvipLevel === 0"
              >普通会员</el-tag
            >
            <el-tag type="warning" size="small" v-else-if="scope.row.pvipLevel === 1"
              >黄金会员</el-tag
            >
            <el-tag type="danger" size="small" v-else-if="scope.row.pvipLevel === 2"
              >白金会员</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="code"
        label="所属标签"
        align="center"
        show-overflow-tooltip
      >
       <div type="text" @click="editHandler(scope.row)" style="cursor:pointer">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            v-for="item in scope.row.vipLabel"
            :key="item.mer_label_name"
            type="warning"
          >
            {{ item.mer_label_name }}
          </el-tag>
        </template>
       </div>
      </el-table-column> -->
      <el-table-column
        prop="soeName"
        label="所属门店"
        width="300"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.soeName }}</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="pvipOpenid" label="openid" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div type="text"  @click="editHandler(scope.row)">
            <a style="color:black" href="#">{{scope.row.pvipOpenid}}</a>
          </div>
        </template>
      </el-table-column> -->

      <!-- <el-table-column
        prop="sbiBalance"
        label="会员余额"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.sbiBalance }}</a>
          </div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="inteInte" label="会员积分" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div type="text"  @click="editHandler(scope.row)">
            <a style="color:black" href="#">{{scope.row.inteInte}}</a>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column
        prop="meName"
        label="所属商户"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div type="text" @click="editHandler(scope.row)">
            <a style="color: black" href="#">{{ scope.row.meName }}</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="icon"
        label="操作"
        width="100"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button @click="editHandlere(scope.row)" type="text" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!--会员详情对话框-->

    <el-drawer
      title="会员详情"
      :visible.sync="dialogVisible"
      size="50%"
      :show-close="true"
      :direction="direction"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-tabs v-model="activeName" style="padding: 0 10px">
        <el-tab-pane label="会员信息" name="memberInfo">
          <el-descriptions class="margin-top" :column="3" border>
            <template slot="extra">
              <el-button
                v-if="hasAuth('member:edit')"
                size="mini"
                type="primary"
                plain
                @click="vipInfo"
                >编辑</el-button
              >
            </template>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                会员姓名
              </template>
              {{ this.editForm.pvipName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                会员昵称
              </template>
              {{ this.editForm.pvipNickname }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-mobile-phone"></i>
                手机号
              </template>
              {{ this.editForm.pvipPhone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-bank-card"></i>
                卡号
              </template>
              {{ this.editForm.pvipCards }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-postcard"></i>
                开卡门店
              </template>
              {{ this.editForm.soeName }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipLevel === 0">
              <template slot="label">
                <i class="el-icon-coin"></i>
                会员等级
              </template>
              {{ "普通会员" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipLevel === 1">
              <template slot="label">
                <i class="el-icon-coin"></i>
                会员等级
              </template>
              {{ "黄金会员" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipLevel === 2">
              <template slot="label">
                <i class="el-icon-coin"></i>
                会员等级
              </template>
              {{ "白金会员" }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                开卡时间
              </template>
              {{ this.editForm.openTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-c-scale-to-original"></i>
                车牌号
              </template>
              {{ this.editForm.pvipLicence }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-female"></i>
                性别
              </template>
              {{ this.editForm.pvipSex }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipPublic === 1">
              <template slot="label">
                <i class="el-icon-open"></i>
                公众号
              </template>
              {{ "已关注" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipPublic === 0">
              <template slot="label">
                <i class="el-icon-turn-off"></i>
                公众号
              </template>
              {{ "未关注" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipIsdel === 1">
              <template slot="label">
                <i class="el-icon-folder-checked"></i>
                会员卡
              </template>
              {{ "未删除" }}
            </el-descriptions-item>
            <el-descriptions-item v-if="editForm.pvipIsdel === 2">
              <template slot="label">
                <i class="el-icon-folder-delete"></i>
                会员卡
              </template>
              {{ "已删除" }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                推荐员工
              </template>
              {{ this.editForm.rendEmployees }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                积分
              </template>
              {{ this.editForm.pvipBouns }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                余额
              </template>
              {{ this.editForm.pvipBalance }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                会员生日
              </template>
              {{ this.editForm.pvipBarth }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-collection-tag"></i>
                标签
              </template>
              <el-tag
                v-for="tag in labelAliInfo"
                :key="tag.svl_id"
                closable
                @close="labelDelete(tag)"
                type="success"
              >
                {{ tag.ali_label_name }}
              </el-tag>
              <el-tag
                v-for="tag in labelMerInfo"
                :key="tag.svl_id"
                closable
                @close="labelDelete(tag)"
                type="warning"
              >
                {{ tag.mer_label_name }}
              </el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="代金卷" name="voucher">
          <!-- <h3>代金卷</h3> -->
          <el-table
            ref="multipleTable"
            :data="giftLogsData"
            tooltip-effect="dark"
            border
            style="width: 100%"
            stripe
            size="small"
            :row-style="{ height: '45px' }"
            :cell-style="{ padding: '1px 0' }"
          >
            <el-table-column type="index" label="序号" width="60" align="center" />
            <el-table-column
              prop="card_name"
              label="卡卷名称"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <!-- <el-table-column
              prop="pvip_cards"
              label="会员卡号"
              show-overflow-tooltip
              align="center"
            ></el-table-column> -->
            <el-table-column
              prop="rec_dm"
              label="领取时间"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sri_oof_count"
              label="是否核销"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="offc_use_dm"
              label="使用时间"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="soe_name"
              label="使用门店"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="whetheroverdm"
              label="是否过期"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @size-change="giftHandleSizeChange"
              @current-change="giftHandleCurrentChange"
              :current-page="giftCurrent"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="giftSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="giftTotal"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="计次卡" name="timesCard">
          <!-- <h3>计次卡</h3> -->
          <el-table
            ref="multipleTable"
            :data="recordsInfosData"
            tooltip-effect="dark"
            border
            style="width: 100%"
            stripe
            size="small"
            :row-style="{ height: '45px' }"
            :cell-style="{ padding: '1px 0' }"
          >
            <el-table-column type="index" label="序号" width="60" align="center" />
            <el-table-column
              prop="card_name"
              label="卡卷名称"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <!-- <el-table-column
              prop="pvip_cards"
              label="会员卡号"
              show-overflow-tooltip
              align="center"
            ></el-table-column> -->
            <el-table-column
              prop="rec_dm"
              label="领取时间"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sri_use_count"
              label="可用次数"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sri_oof_count"
              label="核销次数"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sri_over_dm"
              label="到期时间"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="overdm"
              label="是否到期"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="offc_use_dm"
              label="最近使用时间"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
            <el-table-column
              prop="soe_name"
              label="最近使用门店"
              show-overflow-tooltip
              align="center"
            ></el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @size-change="cardHandleSizeChange"
              @current-change="cardHandleCurrentChange"
              :current-page="cardCurrent"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="cardSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="cardTotal"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    <!--选择群发弹框-->
    <el-dialog
      title="消息发送"
      :visible.sync="dialogPointToPoint"
      width="850px"
      :before-close="PointToPointClose"
      :close-on-click-modal="false"
    >
      <el-form :model="messForm" :rules="editFormRules" ref="messForm" label-width="80px">
        <el-form-item label="参与对象：" label-width="120px">
          <el-input v-model="pvipName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="活动名称：" label-width="120px">
          <el-input v-model="messForm.first"></el-input>
        </el-form-item>
        <el-form-item label="模板id：" label-width="120px" style="display:none">
          <el-input :disabled="true" v-model="messForm.tempId">模板id</el-input>
        </el-form-item>

        <template v-for="(item,index) in formOptions">
          <el-form-item v-if="item.stat==='string' && !item.name.includes('备注')"  :label="item.name+'：'" :key="index" label-width="120px">
            <el-input  v-model="messForm.content[item.field]"></el-input>
          </el-form-item>
           <el-form-item v-else-if="item.stat==='datetime'"  :label="item.name+'：'" :key="index" label-width="120px">
              <el-date-picker
              v-model="messForm.content.field"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </template>
        

        <el-form-item label="模板示例：" label-width="120px">
          <el-input  
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10 }"
            :disabled="true" 
            v-model="messForm.example"></el-input>
        </el-form-item>
        <el-form-item label="发送方式：" label-width="120px" prop="sendMethod">
          <el-radio v-model="messForm.sendMethod" label="1">自定义链接</el-radio>
          <el-radio v-model="messForm.sendMethod" label="2">发送礼品</el-radio>
          <el-radio v-model="messForm.sendMethod" label="3">跳转小程序</el-radio>
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '1'"
          label="自定义链接："
          label-width="120px"
          prop="sendurl"
        >
          <el-input v-model="messForm.sendurl" style="width: 600px"></el-input>
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '2'"
          label="发送礼品："
          label-width="120px"
          prop="voucher"
        >
          <el-checkbox v-model="voucher">代金卷</el-checkbox>
        </el-form-item>

        <el-form-item
          v-if="voucher && messForm.sendMethod === '2'"
          label="送"
          label-width="120px"
        >
          <div
            v-for="(item, index) in arrayGiftData"
            :key="index"
            style="display: flex; align-items: center"
          >
            <el-select v-model="item.type" @change="changeType(index, item)">
              <el-option
                v-for="item in arrayGiftData.giftOptions"
                :key="item.cou_id"
                :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                :value="item.cou_id"
                :disabled="item.disabled"
                @click.native="handleChange(item)"
              >
              </el-option>
            </el-select>
            <el-input-number
              v-model="item.recSurplus"
              :min="1"
              step-strictly
            ></el-input-number>
            张
            <i
              class="el-icon-remove-outline"
              style="color: red; font-size: 20px; margin: 0 5px"
              @click="deletes(item, index)"
            ></i>
          </div>
          <span
            style="color: #3d7fff; cursor: pointer"
            @click="add"
            @change="$forceUpdate()"
            >+ 添加</span
          >
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '2'"
          label=""
          label-width="120px"
          prop="timesCard"
        >
          <el-checkbox v-model="timesCard">计次卡</el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="timesCard && messForm.sendMethod === '2'"
          label="送"
          label-width="120px"
        >
          <div
            v-for="(item, index) in arrayTcountData"
            :key="index"
            style="display: flex; align-items: center"
          >
            <el-select v-model="item.type" @change="changeTypeTcount(index, item)">
              <el-option
                v-for="item in arrayTcountData.tcountOptions"
                :key="item.cou_id"
                :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                :value="item.cou_id"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input-number
              v-model="item.recSurplus"
              :min="1"
              step-strictly
            ></el-input-number>
            张
            <i
              class="el-icon-remove-outline"
              style="color: red; font-size: 20px; margin: 0 5px"
              @click="deletesTcount(item, index)"
            ></i>
          </div>
          <span
            style="color: #3d7fff; cursor: pointer"
            @click="addTcount"
            @change="$forceUpdate()"
            >+ 添加</span
          >
        </el-form-item>

        <el-form-item
          v-if="messForm.sendMethod === '3'"
          label="跳转小程序："
          label-width="120px"
          prop="miniprogram"
        >
        <div style="width:100%;display:flex;flex;flex-direction:column;">
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>APPID：</span>
              <el-input
                type="text"
                style="width: 80%; height: 60px; line-height: 60px;margin-left:20px"
                v-model="messForm.miniprogram.appid"
              ></el-input>
            </div>
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>跳转路径：</span>
              <el-input
                type="text"
                style="width: 80%; height: 60px; line-height: 60px;margin-left:10px"
                v-model="messForm.miniprogram.pathindex"
              ></el-input>
            </div>
        </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PointToPointClose">取消</el-button>
        <el-button type="primary" @click="onSubmit('messForm')">发送</el-button>
      </div>
    </el-dialog>
    <!--直接群发弹出框-->
    <el-dialog
      title="消息发送"
      :visible.sync="dialogMassDispatch"
      width="850px"
      :before-close="PointToPointClose"
      :close-on-click-modal="false"
    >
      <el-form :model="messForm" :rules="editFormRules" ref="messForm" label-width="80px">
        <el-form-item label="参与人员：" label-width="120px">
          <el-input :disabled="true" v-model="messForm.group">全员发送</el-input>
        </el-form-item>
        <el-form-item label="活动名称：" label-width="120px">
          <el-input v-model="messForm.first"></el-input>
        </el-form-item>
        <el-form-item label="模板id：" label-width="120px" style="display:none">
          <el-input :disabled="true" v-model="messForm.tempId">模板id</el-input>
        </el-form-item>

        <template v-for="(item,index) in formOptions">
          <el-form-item v-if="item.stat==='string' && !item.name.includes('备注')"  :label="item.name+'：'" :key="index" label-width="120px">
            <el-input  v-model="messForm.content[item.field]"></el-input>
          </el-form-item>
           <el-form-item v-else-if="item.stat==='datetime'"  :label="item.name+'：'" :key="index" label-width="120px">
              <el-date-picker
              v-model="messForm.content.field"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </template>
        

        <el-form-item label="模板示例：" label-width="120px">
          <el-input  
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10 }"
            :disabled="true" 
            v-model="messForm.example"></el-input>
        </el-form-item>

        <el-form-item label="发送方式：" label-width="120px" prop="sendMethod">
          <el-radio v-model="messForm.sendMethod" label="1">自定义链接</el-radio>
          <el-radio v-model="messForm.sendMethod" label="2">发送礼品</el-radio>
          <el-radio v-model="messForm.sendMethod" label="3">跳转小程序</el-radio>
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '1'"
          label="自定义链接："
          label-width="120px"
          prop="sendurl"
        >
          <el-input v-model="messForm.sendurl" style="width: 600px"></el-input>
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '2'"
          label="发送礼品："
          label-width="120px"
          prop="voucher"
        >
          <el-checkbox v-model="voucher">代金卷</el-checkbox>
        </el-form-item>

        <el-form-item
          v-if="voucher && messForm.sendMethod === '2'"
          label="送"
          label-width="120px"
        >
          <div
            v-for="(item, index) in arrayGiftData"
            :key="index"
            style="display: flex; align-items: center"
          >
            <el-select v-model="item.type" @change="changeType(index, item)">
              <el-option
                v-for="item in arrayGiftData.giftOptions"
                :key="item.cou_id"
                :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                :value="item.cou_id"
                :disabled="item.disabled"
                @click.native="handleChange(item)"
              >
              </el-option>
            </el-select>
            <el-input-number
              v-model="item.recSurplus"
              :min="1"
              step-strictly
            ></el-input-number>
            张
            <i
              class="el-icon-remove-outline"
              style="color: red; font-size: 20px; margin: 0 5px"
              @click="deletes(item, index)"
            ></i>
          </div>
          <span
            style="color: #3d7fff; cursor: pointer"
            @click="add"
            @change="$forceUpdate()"
            >+ 添加</span
          >
        </el-form-item>
        <el-form-item
          v-if="messForm.sendMethod === '2'"
          label=""
          label-width="120px"
          prop="timesCard"
        >
          <el-checkbox v-model="timesCard">计次卡</el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="timesCard && messForm.sendMethod === '2'"
          label="送"
          label-width="120px"
        >
          <div
            v-for="(item, index) in arrayTcountData"
            :key="index"
            style="display: flex; align-items: center"
          >
            <el-select v-model="item.type" @change="changeTypeTcount(index, item)">
              <el-option
                v-for="item in arrayTcountData.tcountOptions"
                :key="item.cou_id"
                :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                :value="item.cou_id"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
            <el-input-number
              v-model="item.recSurplus"
              :min="1"
              step-strictly
            ></el-input-number>
            张
            <i
              class="el-icon-remove-outline"
              style="color: red; font-size: 20px; margin: 0 5px"
              @click="deletesTcount(item, index)"
            ></i>
          </div>
          <span
            style="color: #3d7fff; cursor: pointer"
            @click="addTcount"
            @change="$forceUpdate()"
            >+ 添加</span
          >
        </el-form-item>

        <el-form-item
          v-if="messForm.sendMethod === '3'"
          label="跳转小程序："
          label-width="120px"
          prop="miniprogram"
        >
        <div style="width:100%;display:flex;flex;flex-direction:column;">
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>APPID：</span>
              <el-input
                type="text"
                style="width: 80%; height: 60px; line-height: 60px;margin-left:20px"
                v-model="messForm.miniprogram.appid"
              ></el-input>
            </div>
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>跳转路径：</span>
              <el-input
                type="text"
                style="width: 80%; height: 60px; line-height: 60px;margin-left:10px"
                v-model="messForm.miniprogram.pathindex"
              ></el-input>
            </div>
        </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PointToPointClose">取消</el-button>
        <el-button type="primary" @click="onSubmit('messForm')">发送</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="用户信息"
      :visible.sync="dialogFormInfo"
      width="700px"
      :before-close="formInfoClose"
      :close-on-click-modal="false"
    >
      <el-form ref="editFormInfo" :model="editFormInfo" label-width="80px">
        <el-form-item label="会员卡号" label-width="100px">
          <el-input v-model="editFormInfo.pvipCards" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="会员姓名" label-width="100px">
          <el-input v-model="editFormInfo.pvipName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="会员昵称" label-width="100px">
          <el-input v-model="editFormInfo.pvipNickname" :disabled="true"></el-input>
        </el-form-item>
       
        <el-form-item label="手机号" label-width="100px">
          <el-input v-model="editFormInfo.pvipPhone" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="会员等级" label-width="100px">
          <el-radio-group v-model="editFormInfo.pvipLevel">
            <el-radio :disabled="true" :label="0">普通会员</el-radio>
            <el-radio :disabled="true" :label="1">黄金会员</el-radio>
            <el-radio :disabled="true" :label="2">白金会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关注公众号" label-width="100px">
          <el-radio-group v-model="editFormInfo.pvipPublic">
            <el-radio :disabled="true" :label="1">是</el-radio>
            <el-radio :disabled="true" :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="性别" label-width="100px">
          <el-radio-group v-model="editFormInfo.pvipSex">
            <el-radio :disabled="true" label="男">男</el-radio>
            <el-radio :disabled="true" label="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--会员详情点对点对话框-->
    <el-dialog
      title="会员信息修改"
      :visible.sync="dialogVipInfo"
      width="800px"
      :before-close="vipInfoClose"
      :close-on-click-modal="false"
    >
      <el-form ref="vipForm" :model="vipForm" :rules="vipFormRules" label-width="80px">
        <el-form-item label="会员姓名" prop="pvipName">
          <el-col :span="9">
            <el-input v-model="vipForm.pvipName" style="width: 230px"></el-input>
          </el-col>
          <el-col :span="11">
            <el-form-item label="手机号" prop="pvipPhone">
              <el-input v-model="vipForm.pvipPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="会员昵称">
          <el-col :span="9">
            <el-input v-model="vipForm.pvipNickname" style="width: 230px"></el-input>
          </el-col>
          <el-col :span="11">
            <el-form-item label="卡号">
              <el-input v-model="vipForm.pvipCards" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="开卡时间" prop="pvipOpDm">
          <el-col :span="9">
            <el-date-picker
              v-model="vipForm.pvipOpDm"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 230px"
              :disabled="true"
            ></el-date-picker>
          </el-col>
          <el-col :span="11">
            <el-form-item label="会员生日">
              <el-input v-model="vipForm.pvipBarth"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="性别">
          <el-col :span="7">
            <el-radio-group v-model="vipForm.pvipSex">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="14">
            <el-form-item label="会员等级">
              <el-radio-group v-model="vipForm.pvipLevel">
                <el-radio :label="0">普通会员</el-radio>
                <el-radio :label="1">黄金会员</el-radio>
                <el-radio :label="2">白金会员</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="开卡门店" prop="pvipSoeId">
          <el-col :span="9">
            <el-select
              v-model="vipForm.pvipSoeId"
              style="width: 230px"
              placeholder="请选择"
            >
              <el-option label="总部开卡" :value="0"
                >&#45;&#45;总部开卡&#45;&#45;</el-option
              >
              <el-option
                v-for="item in soeIdOptions"
                :key="item.soe_id"
                :label="item.soe_name"
                :value="item.soe_id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="11">
            <el-form-item label="公众号">
              <el-radio-group v-model="vipForm.pvipPublic">
                <el-radio :label="1">已关注</el-radio>
                <el-radio :label="0">未关注</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-col :span="9">
            <el-input v-model="vipForm.pvipLicence" style="width: 230px"></el-input>
          </el-col>
          <el-col :span="11">
            <el-form-item label="推荐员工">
              <el-select
                v-model="vipForm.pvipRecomId"
                style="width: 230px"
                placeholder="请选择"
              >
                <el-option label="无" :value="0">&#45;&#45;无&#45;&#45;</el-option>
                <el-option
                  v-for="item in meIdOptions"
                  :key="item.user_id"
                  :label="item.name"
                  :value="item.user_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="会员卡">
          <el-col :span="12">
            <el-radio-group v-model="vipForm.pvipIsdel">
              <el-radio :label="1">未删除</el-radio>
              <el-radio :label="0">已删除</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitVipForm('vipForm')">保 存</el-button>
        <el-button @click="vipInfoClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/private";
import temp from "../../api/temp";
import user from "@/api/user";
import sys from "@/api/sysUser";
import moment from "moment";
export default {
  name: "MemberList",
  data() {
    let aaaaa = "";
    let isTrueUrl = (rule, value, callback) => {
      let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的链接"));
      } else {
        callback();
      }
    };
    return {
      isExport: false,
      tableLoading: false,
      otherForm: {
        other: [
          {
            type: "",
          },
        ],
      },
      voucher: "",
      timesCard: "",
      searchForm: {
        pvipPhone: "",
        pvipName: "",
        pvipNickname: "",
        pvipSoeId: -1,
      },
      delBtlStatu: true,
      choiceDisabled: true, //选择群发
      total: 10,
      size: 10,
      aliLabelIde: [],
      current: 1,
      giftTotal: 10,
      giftSize: 10,
      giftCurrent: 1,
      cardTotal: 10,
      cardSize: 10,
      cardCurrent: 1,
      dictsAssetProvinceclass: [],
      tableData: [],
      checkList: [],
      nohigh: true,
      giftLogsData: [], //代金卷列表
      recordsInfosData: [], //计次卡列表
      activeName: "memberInfo", //页签默认值
      dialogVisible: false, //会员详情弹出窗口
      dialogPointToPoint: false, //点对点发送弹出窗口
      dialogMassDispatch: false, //直接群发弹出窗口
      dialogTempToPoint: false, //模板群发
      dialogFormInfo: false,
      dialogVipInfo: false, //会员信息弹出框
      labelDisable: false,
      labelTitle: "标签同步",
      meName: "",
      LevelOptions: [
        {
          value: 0,
          label: "普通会员",
        },
        {
          value: 1,
          label: "黄金会员",
        },
        {
          value: 2,
          label: "白金会员",
        },
      ],
      Level: "",
      pvipIsdelOptions: [
        {
          value: 0,
          label: "是",
        },
        {
          value: 1,
          label: "否",
        },
      ],
      pvipIsdel: "",
      pvipPublicOptions: [
        {
          value: 1,
          label: "已关注",
        },
        {
          value: 0,
          label: "未关注",
        },
      ],
      pvipPublic: "",
      editForm: {},
      vipForm: {},
      labelAliInfo: {}, //商盟标签
      labelMerInfo: {}, //商户标签
      messForm: {
        first:"",//活动名称
        sendObj: "",
        sendType: "",
        pvipOpenid: "",
        sendurl: "",
        sendMethod: "",
        pvipNickname: "",
        group: "",
        miniprogram:{
          appid:"",
          pathindex:''
        },

        title:"",
        content:{},
        tempId:"",
        example:""
      },
      formOptions:[],
      editFormInfo: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      pvipName: "",
      totalPvIpCards: 0,
      // giftOptions:[],//卡卷
      // recSurplus:0,
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      cardOptions: [], //参与人员
      soeIdOptions: [], //所属门店
      meIdOptions: [],
      sendPersons: 0, //群发人数
      arrayGiftData: [
        {
          giftOptions: [], //卡卷
          recSurplus: 1,
          type: "",
        },
      ],

      arrayTcountData: [
        {
          tcountOptions: [], //计次卡
          recSurplus: 1,
          type: "",
        },
      ],
      value: [],
      editFormRules: {
        first: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        // gift: [{ required: true, message: "请选择代金卷", trigger: "blur" }],
        // giftVoucher:[{ required: true, message: "请选择礼品类型", trigger: "blur" }],
        //timesCard:[{ required: true, message: "请选择计次卡", trigger: "blur" }],
        // voucher: [{ required: true, message: "请选输入代码直", trigger: "blur" }],
        orderNo: [{ required: true, message: "请选输入排序字段", trigger: "blur" }],
        codeDesc: [{ required: true, message: "请选输入代码描述", trigger: "blur" }],
     
        sendMethod: [{ required: true, message: "请选发送方式", trigger: "blur" }],
        sendurl: [
          { required: true, message: "请选输入自定义链接", trigger: "blur" },
          { validator: isTrueUrl, trigger: "blur" },
        ],
      },
      vipFormRules: {
        pvipName: [{ required: true, message: "请输入会员姓名", trigger: "blur" }],
        pvipPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: "1[3|4|5|7|8][0-9]{9}$",
            message: "请输入正确手机号",
            trigger: "blur",
          },
        ],
        pvipOpDm: [{ required: true, message: "请选择开发时间", trigger: "blur" }],
        pvipSoeId: [{ required: true, message: "请选择开卡门店", trigger: "blur" }],
      },
      multipleSelection: [], //选中、取消选中
      openTime: "",
      offcUseDm: "",
      radio: "",
      pvipCards: "",
      direction: "rtl",

    };
  },
  created() {
    this.getUserInfo();
    this.getListSyPrivateVip();
    this.getListVipCard();
    this.getListSyCoupons();
    this.queryByMeid();
    //this.listSyStore();
  },
  methods: {
    async getUserInfo() {
      await user.userinfo().then((res) => {
        let meId = res.data.data.result.meId;
        let alId = res.data.data.result.alId;
        this.listSyStore(meId);
        if (meId != null) {
          this.queryByMeid(meId);
        }
      });
    },

    listSyStore(meId) {
      sys.getListStore().then((res) => {
        this.soeIdOptions = res.data.data.result;
        if (meId !== null) {
          this.meName = res.data.data.meName + "(总部)";
        }
      });
    },
    queryByMeid() {
      user.queryByMeid().then((res) => {
        this.meIdOptions = res.data.data.result;
      });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getListSyPrivateVip();
    },

    handleCurrentChange(val) {
      this.current = val;
      this.getListSyPrivateVip();
    },
    giftHandleSizeChange(val) {
      this.giftSize = val;
      this.getListGiftLogs(this.pvipCards);
    },
    giftHandleCurrentChange(val) {
      this.giftCurrent = val;
      this.getListGiftLogs(this.pvipCards);
    },
    cardHandleSizeChange(val) {
      this.cardSize = val;
      this.getListRecordsInfos(this.pvipCards);
    },
    cardHandleCurrentChange(val) {
      this.cardCurrent = val;
      this.getListRecordsInfos(this.pvipCards);
    },
    handleSelectionChange(array) {
      this.multipleSelection = array;
      if (array.length > 0) {
        this.choiceDisabled = false;
      } else {
        this.choiceDisabled = true;
      }
    },
    showHighSerch() {
      this.nohigh = !this.nohigh;
    },
    selectItem(list, row) {
      const ids = this.tableData.map((i) => {
        return i.pvipCards;
      });
      for (var i = 0; i < this.checkList.length; i++) {
        if (ids.indexOf(this.checkList[i].pvipCards) > -1) {
          this.checkList.splice(i, 1);
          i--;
        }
      }
      const arr = [...list, ...this.checkList];
      this.checkList = Array.from(new Set(arr));
      let sendObj = [];
      let pvipName = [];
      let pvipOpenid = [];
      this.checkList.forEach((res) => {
        sendObj.push(res.pvipId);
        pvipName.push(res.pvipNickname);
        pvipOpenid.push(res.pvipOpenid);
      });
      this.pvipName = pvipName.join(",");
      this.messForm.pvipNickname = pvipName.join(",");
      this.messForm.sendObj = sendObj.join(",");
      this.messForm.pvipOpenid = pvipOpenid.join(",");
    },
    selectAll(selection) {
      const ids = this.tableData.map((i) => {
        return i.pvipCards;
      });
      for (var i = 0; i < this.checkList.length; i++) {
        if (ids.indexOf(this.checkList[i].pvipCards) > -1) {
          this.checkList.splice(i, 1);
          i--;
        }
      }

      const arr = [...selection, ...this.checkList];
      this.checkList = Array.from(new Set(arr));
      let sendObj = [];
      let pvipName = [];
      let pvipOpenid = [];
      this.checkList.forEach((res) => {
        sendObj.push(res.pvipId);
        pvipName.push(res.pvipNickname);
        pvipOpenid.push(res.pvipOpenid);
      });
      this.pvipName = pvipName.join(",");
      this.messForm.sendObj = sendObj.join(",");
      this.messForm.pvipOpenid = pvipOpenid.join(",");
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.checkList.forEach((row1) => {
          for (let i = 0; i < this.tableData.length; i++) {
            if (row1.pvipId === this.tableData[i].pvipId) {
              this.$refs.multipleTables.toggleRowSelection(this.tableData[i], true);
            }
          }
        });
      });
    },
    search() {
      this.checkList = [];
      this.current = 1;
      this.getListSyPrivateVip();
    },
    exportBtn() {
      //导出
      this.isExport = true;
      this.getListSyPrivateVip();
    },
    refresh() {
      this.searchForm.pvipSoeId = -1;
      this.searchForm = {
        pvipPhone: "",
        pvipName: "",
        pvipNickname: "",
        pvipSoeId: -1,
      };

      this.openTime = "";
      this.offcUseDm = "";
      this.pvipPublic = "";
      this.Level = "";
      this.value = "";
      this.pvipIsdel = "";
      this.checkList = [];
      this.getListSyPrivateVip();
    },
    syncLabel() {
      this.labelDisable = true;
      this.labelTitle = "标签同步中...";
      api.getSyncLabel().then((res) => {
        console.log(res);
        if (res.data.data.result) {
          console.log(res.data.data.result);
          this.labelDisable = false;
          this.labelTitle = "标签同步";
          this.$notify({
            title: "消息提示",
            message: "标签同步完成",
            position: "bottom-right",
            type: "success",
          });
          this.getListSyPrivateVip();
        }
      });
    },
    infoSynchro() {
      const loading = this.$loading({
        lock: true,
        text: "数据同步中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 5000);
      api.getInfoSynchro().then((res) => {
        if (res.data.data.result === "success") {
          this.$notify({
            title: "消息提示",
            message: "数据同步成功",
            position: "bottom-right",
            type: "success",
          });
          loading.close();
          this.getListSyPrivateVip();
        } else {
          this.$notify({
            title: "消息提示",
            message: "数据同步失败",
            position: "bottom-right",
            type: "error",
          });
          loading.close();
        }
        loading.close();
      });
    },
    submitForm() {
      let parmet = {
        aliLabelIde: this.aliLabelIde,
        multipleSelection: this.multipleSelection,
      };
      api.saveVipLab(parmet).then((res) => {
        this.$notify({
          title: "prefect",
          message: "操作成功",
          position: "bottom-right",
          type: "success",
          onClose: () => {
            this.getListVipCard();
          },
        });
        this.dialogVisible = false;
        // this.refresh();
      });
    },
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size;
      return index;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    dialogPatch() {
      // messForm.content
      temp.getOneTemp().then(res=>{
        this.messForm.title=res.data.data.result.title
        this.messForm.example=res.data.data.result.example
        this.messForm.tempId=res.data.data.result.tempId
        this.formOptions=res.data.data.result.content
      })
      this.dialogMassDispatch = true;
      this.messForm.group = "全员发送";
    },
    dialogToPoint() {
      temp.getOneTemp().then(res=>{
        this.messForm.title=res.data.data.result.title
        this.messForm.example=res.data.data.result.example
        this.messForm.tempId=res.data.data.result.tempId
        this.formOptions=res.data.data.result.content
      })
      this.dialogPointToPoint = true;
      this.messForm.group = "";
    },
    PointToPointClose() {
      this.dialogPointToPoint = false;
      this.dialogMassDispatch = false;

      this.arrayGiftData.giftOptions.forEach((v, index) => {
        this.arrayGiftData.splice(index); //删除数组中对应的数据也就是将这个位置的框删除
        v.disabled = false;
      });
      this.arrayTcountData.tcountOptions.forEach((v, index) => {
        this.arrayTcountData.splice(index); //删除数组中对应的数据也就是将这个位置的框删除
        v.disabled = false;
      });
      this.arrayGiftData.push({
        giftOptions: [],
        recSurplus: 1,
        type: "",
      });
      this.arrayTcountData.push({
        tcountOptions: [],
        recSurplus: 1,
        type: "",
      });
      //清空对象-
      this.messForm.sendType = "";
      this.messForm.pvipOpenid = "";
      this.messForm.sendurl = "";
      this.messForm.sendMethod = "";
      this.messForm.pvipNickname = "";
      this.timesCard = false;
      this.voucher = false;
      this.messForm.content={};
      this.messForm.example="";
      this.messForm.tempId="";
      this.messForm.title="";
      this.messForm.miniprogram.appid="";
      this.messForm.miniprogram.pathindex="";
      // this.messForm.voucher = '';
      // this.messForm.timesCard = '';
      //清空选择选中的会员
      // this.checkList = [];
      // this.getListSyPrivateVip();
    },
    formInfoClose() {
      this.dialogFormInfo = false;
      this.editFormInfo = {};
    },
    editHandlere(row) {
      this.editFormInfo = row;
      this.dialogFormInfo = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
      this.aliLabelIde = [];
      this.totalPvIpCards = 0;
    },
    async getListSyPrivateVip() {
      this.tableLoading = true;
      await api
        .getListSyPrivateVip(
          this.searchForm,
          this.current,
          this.openTime,
          this.offcUseDm,
          this.pvipPublic,
          this.Level,
          this.pvipIsdel,
          this.size,
          this.isExport
        )
        .then((res) => {
          if (!this.isExport) {
            this.tableData = res.data.data.result.records;
            this.size = res.data.data.result.size;
            // this.current = res.data.data.result.current;
            this.total = res.data.data.result.total;
          } else {
            //导出
            let columns = [
              { title: "序号", key: "pvipId" },
              { title: "手机号", key: "pvipPhone" },
              { title: "会员姓名", key: "pvipName" },
              { title: "会员昵称", key: "pvipNickname" },
              { title: "注册时间", key: "openTime" },
              { title: "推荐员工", key: "rendEmployees" },
              { title: "是否关注公众号", key: "pvipPublicStr" },
              { title: "是否删除卡", key: "pvipIsdelStr" },
              { title: "会员等级", key: "pvipLevelStr" },
              { title: "所属标签", key: "merLabelName" },
              { title: "所属门店", key: "soeName" },
              { title: "所属商户", key: "meName" },
            ];
            if (res.data.data.result.records.length > 0) {
              this.export2Excel(columns, res.data.data.result.records, "会员信息");
            }
            this.isExport = false;
          }
          this.tableLoading = false;
        });
      this.toggleSelection();
    },
    export2Excel(columns, list, name) {
      require.ensure([], () => {
        const {
          exportJsonToExcel,
        } = require("../../components/exportExcel/Export2Excel");
        let tHeader = [];
        let fileterVal = [];

        if (!columns) {
          return;
        }
        columns.forEach((item) => {
          tHeader.push(item.title);
          fileterVal.push(item.key);
        });
        const data = list.map((v) => fileterVal.map((j) => v[j]));
        exportJsonToExcel(tHeader, data, name, true);
      });
    },
    getListVipCard() {
      api.getListVipCard().then((res) => {
        this.cardOptions = res.data.data.result;
      });
    },
    handleChange(item) {
      this.arrayGiftData.recSurplus = item.rec_surplus;
    },
    changeType(index, item) {
      //console.log(item.giftOptions);
      this.arrayGiftData.giftOptions.forEach((v) => {
        v.disabled = false;
        for (var i = 0; i < this.arrayGiftData.length; i++) {
          if (this.arrayGiftData[i].type === v.cou_id) {
            v.disabled = true;
            v.recSurplus = this.arrayGiftData[i].recSurplus;
          }
        }
      });
    },
    changeTypeTcount() {
      this.arrayTcountData.tcountOptions.forEach((v) => {
        v.disabled = false;
        for (var i = 0; i < this.arrayTcountData.length; i++) {
          if (this.arrayTcountData[i].type === v.cou_id) {
            v.disabled = true;
          }
        }
      });
    },
    getListSyCoupons() {
      api.getListSyCoupons(0).then((res) => {
        this.arrayGiftData.giftOptions = res.data.data.result;
      });
      api.getListSyCoupons(1).then((res) => {
        this.arrayTcountData.tcountOptions = res.data.data.result;
      });
    },
    edHid() {
      let parme = {
        multipleSelection: this.multipleSelection,
      };
      api.selectHxeGc(parme).then((res) => {
        this.aliLabelId = res.data.data.result;
      });
      this.dialogVisible = true;
    },
    add() {
      this.arrayGiftData.push({
        giftOptions: [],
        recSurplus: 1,
        type: "",
      });
    },
    addTcount() {
      this.arrayTcountData.push({
        tcountOptions: [],
        recSurplus: 1,
        type: "",
      });
    },
    deletes(item, index) {
      if (this.arrayGiftData.length <= 1) {
        ///如果只有一个框则不可以删除
        return false;
      }
      this.arrayGiftData.splice(index, 1); //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayGiftData.giftOptions.forEach((v) => {
        if (v.cou_id === item.type && v.disabled) {
          v.disabled = false;
        }
      });
    },
    deletesTcount(item, index) {
      if (this.arrayTcountData.length <= 1) {
        ///如果只有一个框则不可以删除
        return false;
      }
      this.arrayTcountData.splice(index, 1); //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayTcountData.tcountOptions.forEach((v) => {
        if (v.cou_id === item.type && v.disabled) {
          v.disabled = false;
        }
      });
    },
    editHandler(row) {
      console.log(row);
      this.editForm = row;
      this.vipForm = row;
      this.activeName = "memberInfo";
      api.getQueryBySvlId(row.pvipId).then((res) => {
        let aliArray = []; //商盟标签
        let merArray = []; //商户标签
        res.data.data.result.forEach((res) => {
          if (res.mer_label_name !== undefined) {
            merArray.push({
              mer_label_name: res.mer_label_name,
              svl_id: res.svl_id,
              state: 1,
            });
          }
          if (res.ali_label_name !== undefined) {
            aliArray.push({
              ali_label_name: res.ali_label_name,
              svl_id: res.svl_id,
              state: 0,
            });
          }
        });
        this.labelAliInfo = aliArray;
        this.labelMerInfo = merArray;
      });
      // api.totalPvIpCards(row.pvipCards).then((res) => {
      //   this.totalPvIpCards = res.data.data.result[0].cu;
      // });
      this.getListGiftLogs(row.pvipCards); //代金卷列表
      this.getListRecordsInfos(row.pvipCards); //计次卡列表
      this.dialogVisible = true;
    },
    getListGiftLogs(pvipCards) {
      this.pvipCards = pvipCards;
      api.getListGiftLogs(pvipCards, this.giftCurrent, this.giftSize).then((res) => {
        this.giftLogsData = res.data.data.result.records;
        this.giftSize = res.data.data.result.size;
        this.giftTotal = res.data.data.result.total;
      });
    },
    getListRecordsInfos(pvipCards) {
      api.getListRecordsInfos(pvipCards, this.cardCurrent, this.cardSize).then((res) => {
        this.recordsInfosData = res.data.data.result.records;
        this.cardSize = res.data.data.result.size;
        this.cardTotal = res.data.data.result.total;
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(this.messForm)
        if (valid) {
          this.messForm.sendType = 1;
          let sendMethod = this.messForm.sendMethod;
          if (this.messForm.group === "全员发送") {
            this.messForm.sendType = 2;
          }

          let arrayGiftData = []; //卡卷
          let arrayTcountData = []; //计次卡
          //发送方式2为礼品发送
          if (sendMethod === "2") {
            this.messForm.sendurl = "";
            //代金卷不为空 不为false进去判断条件
            if (this.voucher != "" && this.voucher != false) {
              //循环代金卷数据，将卡卷信息发送张数等数据统一拼到数组中
              this.arrayGiftData.forEach((res) => {
                this.arrayGiftData.giftOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    let validDate = "";
                    if (data.cou_valid_date_type === "0") {
                      var date = moment();
                      validDate = date.add(30, "days").format("YYYY-MM-DD HH:mm:ss");
                    } else if (data.cou_valid_date_type === "1") {
                      let cou_valid_date_range = data.cou_valid_date_range.split(",");
                      validDate = cou_valid_date_range[1];
                    }
                    arrayGiftData.push({
                      cou_batch: data.cou_batch,
                      cou_color: data.cou_color,
                      cou_count: data.cou_count,
                      cou_desc: data.cou_desc,
                      cou_id: data.cou_id,
                      cou_know: data.cou_know,
                      cou_me_id: data.cou_me_id,
                      cou_reminder: data.cou_reminder,
                      cou_subhead: data.cou_subhead,
                      cou_title: data.cou_title,
                      cou_type: data.cou_type,
                      cou_writer_off: data.cou_writer_off,
                      disabled: data.disabled,
                      recSurplus: res.recSurplus,
                      frequency: data.frequency,
                      surplus: data.surplus,
                      sriOverDm: validDate,
                    });
                  }
                });
              });
            }
            //计次卡不为空 不为false进去判断条件
            if (this.timesCard != "" && this.timesCard != false) {
              //循环计次卡数据，将卡卷信息发送张数等数据统一拼到数组中
              this.arrayTcountData.forEach((res) => {
                this.arrayTcountData.tcountOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    let validDate = "";
                    if (data.cou_valid_date_type === "0") {
                      var date = moment();
                      validDate = date.add(30, "days").format("YYYY-MM-DD HH:mm:ss");
                    } else if (data.cou_valid_date_type === "1") {
                      let cou_valid_date_range = data.cou_valid_date_range.split(",");
                      validDate = cou_valid_date_range[1];
                    }
                    arrayTcountData.push({
                      cou_batch: data.cou_batch,
                      cou_color: data.cou_color,
                      cou_count: data.cou_count,
                      cou_desc: data.cou_desc,
                      cou_id: data.cou_id,
                      cou_know: data.cou_know,
                      cou_logo: data.cou_logo,
                      cou_reminder: data.cou_reminder,
                      cou_subhead: data.cou_subhead,
                      cou_title: data.cou_title,
                      cou_type: data.cou_type,
                      sriOverDm: validDate,
                      disabled: data.disabled,
                      recSurplus: res.recSurplus,
                      frequency: data.frequency,
                      surplus: data.surplus,
                    });
                  }
                });
              });
            }
            if (sendMethod === "2") {
              if (arrayGiftData.length === 0 && arrayTcountData.length === 0) {
                this.$notify({
                  title: "提示信息",
                  message: "请选择代金卷或计次卡",
                  type: "error",
                  position: "bottom-right",
                });
                return;
              }
            }
            //console.log(arrayGiftData);
            let quSent = this.messForm.sendObj.split(",");
            let sent = quSent.length;
            for (var i = 0; i < arrayGiftData.length; i++) {
              //剩余数量
              let surplus = arrayGiftData[i].surplus;
              //发送数量
              let recSurplus = arrayGiftData[i].recSurplus;
              //计算库存发放数量，发放张数大于库存返回到页面提示
              let quantity = surplus - sent * recSurplus;
              if (quantity < 0) {
                this.$notify({
                  title: "提示信息",
                  message: arrayGiftData[i].cou_title + "超出卡卷剩余数量",
                  type: "error",
                  position: "bottom-right",
                });

                return;
              }
            }
            for (var i = 0; i < arrayTcountData.length; i++) {
              //剩余数量
              let surplus = arrayTcountData[i].surplus;
              //发送数量
              let recSurplus = arrayTcountData[i].recSurplus;
              //计算库存发放数量，发放张数大于库存返回到页面提示
              let quantity = surplus - sent * recSurplus;
              if (quantity < 0) {
                this.$notify({
                  title: "提示信息",
                  message: arrayTcountData[i].cou_title + "超出卡卷剩余数量",
                  type: "error",
                  position: "bottom-right",
                });
                return;
              }
            }
          }
          let openStartDate = "";
          let openEndDate = "";
          if (this.openTime != null && this.openTime != "") {
            openStartDate = this.openTime[0];
            openEndDate = this.openTime[1];
          }
          let params = {
            jsonParams: JSON.stringify(this.messForm),
            arrayGiftData: arrayGiftData,
            arrayTcountData: arrayTcountData,
            searchForm: JSON.stringify(this.searchForm),
            openStartDate: openStartDate,
            openEndDate: openEndDate,
            pvipPublic: this.pvipPublic,
            Level: this.Level,
            pvipIsdel: this.pvipIsdel,
          };
          const loading = this.$loading({
            lock: true,
            text: "卡卷发送中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            loading.close();
            this.PointToPointClose();
            this.getListSyCoupons();
            this.getListSyPrivateVip();
          }, 1000);
          temp.tempMember(params).then((res) => {
            if (res.data.data.result === "success") {
              loading.close();
              this.$notify({
                title: "提示信息",
                message: "发送成功",
                type: "success",
                position: "bottom-right",
              });
              //this.$router.go(0);
              this.PointToPointClose();
              this.getListSyCoupons();
              this.getListSyPrivateVip();

              //this.arrayGiftData =[]
            } else if (res.data.data.result === "error") {
              this.$notify({
                title: "提示信息",
                message: "找不到accessToken，请在微信公众号配置IP白名单",
                type: "error",
                position: "bottom-right",
              });
              loading.close();
            } else if (res.data.data.result === "quantity") {
              this.$notify({
                title: "提示信息",
                message: res.data.data.title + "超出卡卷剩余数量",
                type: "error",
                position: "bottom-right",
              });
              loading.close();
            } else if (res.data.data.result === "userNull") {
              this.$notify({
                title: "提示信息",
                message: "未查询到会员",
                type: "error",
                position: "bottom-right",
              });
              loading.close();
            } else {
              loading.close();
            }
          });
        } else {
          return false;
        }
      });
    },
    labelDelete(row) {
      api.getDeleteBySvlId(row.svl_id).then((res) => {
        if (res.data.data.result) {
          this.$notify({
            title: "提示信息",
            message: "删除成功",
            type: "success",
            position: "bottom-right",
          });
          if (row.state === 0) {
            this.labelAliInfo.splice(this.labelAliInfo.indexOf(row), 1);
          } else if (row.state === 1) {
            this.labelMerInfo.splice(this.labelMerInfo.indexOf(row), 1);
          }
        } else {
          this.$notify({
            title: "提示信息",
            message: "删除失败",
            type: "error",
            position: "bottom-right",
          });
        }
      });
    },
    vipInfo() {
      this.dialogVipInfo = true;
    },
    vipInfoClose() {
      this.dialogVipInfo = false;
    },
    submitVipForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.updateSyPrivate(this.vipForm).then((res) => {
            if (res.data.data.result) {
              this.$notify({
                title: "提示信息",
                message: "编辑成功",
                type: "success",
                position: "bottom-right",
              });
              this.getListVipCard();
              this.getListSyPrivateVip();
              this.dialogVisible = false;
              this.dialogVipInfo = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
.circle {
  border-radius: 80%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.imageName {
  position: absolute;
  top: 10px;
  margin-left: 12%;
  /* margin-top: 10px; */
}
.nickname {
  margin: 20px 0;
}
.popov {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
